@import "~@hawaiianair/branding/styles/responsive.scss";
@import "~@hawaiianair/branding/styles/mixins.scss";
@import "~@hawaiianair/branding/styles/fonts.scss";
@import "~@hawaiianair/branding/styles/colors.scss";
// Material Design Theme
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Colors
$backgroundGray: #f1f1f1;
$elitePlatinum: #b1b3b4;
$secondaryText: #515354;
$tertiaryText: #6f6f6f;
$successGreen: #7ac041;

// text styles
h1 {
  @include font-title(64px);
  color: $black;
}

h2 {
  @include font-title(56px);
  color: $black;
}

h3 {
  @include font-title(48px);
}

h4 {
  @include font-body(40px);
  letter-spacing: -1px;
  line-height: 40px;
  color: $black;
  &.medium {
    @include font-body-medium(40px);
    letter-spacing: -1px;
    line-height: 40px;
    color: $black;
  }
  &.mobile {
    @include font-title(40px);
    letter-spacing: -1px;
    line-height: 40px;
    color: $black;
  }
}

h5 {
  @include font-body(32px);
  color: $black;
  &.medium {
    @include font-body-medium(32px)
  }
}

h6 {
  @include font-body(24px);
  color: $black;
  &.medium {
    @include font-body-medium(24px)
  }
}

section {
  @include page-section(left, 0, 2rem);
  background-color: $backgroundGray;
  min-height: 90%;
}

footer {
  background-color: $white;
  border-top: 3px solid $alohaPink;
}

.subtitle {
  @include font-body(20px);
  color: $black;
  line-height: 32px;
  &-bold {
    @include font-title-heavy(20px);
    color: $black;
    line-height: 32px;
  }
}

.call-out {
  @include font-body(18px);
  color: $black;
}

.call-out-bold {
  @include font-title-heavy(18px);
  color: $black;
}

.body-copy {
  @include font-body(16px);
  color: $black;
  line-height: 24px;
  &-light-text {
    @include font-body(16px);
    color: $secondaryText;
    line-height: 24px;
  }
  &-bold {
    @include font-title-heavy(16px);
    color: $black;
    line-height: 24px;
    &.medium {
      @include font-body-medium(16px);
    }
  }
}

.body-text-medium {
  @include font-body-medium(18px);
  color: $black;
  line-height: 24px;
}

.foot-note {
  @include font-body(14px);
  color: $black;
  line-height: 16px;
}

.foot-note-bold {
  @include font-title-heavy(14px);
  color: $black;
}

.small {
  @include font-body(12px);
  color: $black;
  line-height: 16px;
  &-light-text {
    @include font-body(12px);
    color: $secondaryText;
    line-height: 16px;
  }
  &-tertiary-text {
    @include font-body(12px);
    color: $secondaryText;
    line-height: 16px;
  }
  &-bold {
    @include font-title-heavy(12px);
    color: $tertiaryText;
    line-height: 16px;
  }
}

.small-white-text {
  @include font-body(12px);
  color: $white;
}

.bold {
  font-weight: bold;
}

.expansion-open {
  overflow: hidden;
}

.background {
  background-color: $backgroundGray;
}

@media screen and (max-width: 768px) {
  .background {
    background-color: $white;
  }

  mat-dialog-container {
    border-radius: 0px !important;
  }
}

.aloha-pink {
  color: $alohaPink
}

.royal-purple {
  color: $royalPurple
}

.ocean-blue {
  color: $oceanBlue;
}

.sunset-orange {
  color: $sunsetOrange;
}

.white {
  color: $white;
}

.background-white {
  background-color: $white;
}

.black {
  color: $black;
}

.breeze-gray {
  color: $breezeGray;
}

.evening-gray {
  color: $breezeGray;
}

.background-gray {
  background-color: $backgroundGray;
}

.border-gray {
  color: $borderGray;
}

.shadow {
  color: $shadow;
}

.dark-text {
  color: $darkText;
}

.secondary-text {
  color: $secondaryText;
}

.light-text {
  color: $lightText;
}

.tertiary-text {
  color: $tertiaryText;
}

.end-flow {
  color: $endFlower;
}

.hover-pink {
  color: $hoverPink;
}

.hover-purple {
  color: $hoverPurple;
}

.required {
  color: $required;
}

.mask {
  color: $mask;
}

.success-green {
  color: $successGreen;
}

// button styles
button {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  // style
  &.btn {
    @include font-title-heavy(16px);
    margin: 1rem;
    border-radius: 4px;
    min-width: 264px;
    height: 48px;
    line-height: 2.5rem;

    // btn btn-default style
    &.btn-default {
      color: $white;
      background-color: $alohaPink;
      border-color: $alohaPink;
      .small {
        color: $white;
        line-height: 1.33;
      }
      &:hover:enabled {
        color: $white;
        background-color: #d83ca0 !important;
        .small {
          color: $white;
        }
      }
      &:disabled {
        color: $secondaryText !important;
        background-color: $breezeGray !important;
        cursor: not-allowed !important;
        border-color: $breezeGray;
        .small {
          color: $secondaryText !important;
        }
      }
      &:active:enabled {
        color: $white !important;
        background-color: #a5096d !important;
      }
      &:focus:enabled {
        color: $white;
        background-color: $alohaPink;
        box-shadow: 0 0 8px 0 $oceanBlue;
      }
    }

    // btn btn-secondary style
    &.btn-secondary {
      color: $alohaPink;
      background-color: $white;
      border: solid 1px $elitePlatinum !important;
      .small {
        color: $secondaryText;
        line-height: 1.33;
      }
      &:hover:enabled {
        color: $white !important;
        background-color: $alohaPink !important;
        .small {
          color: $white !important;
        }
      }
      &:disabled {
        color: #6f6f6f !important;
        background-color: $white !important;
        border: solid 1px $breezeGray !important;
        cursor: not-allowed !important;
      }
      &:active:enabled {
        color: $alohaPink !important;
        border: solid 1px $alohaPink !important;
        background-color: #fbe7f4 !important;
        .small {
          color: $secondaryText !important;
        }
      }
      &:focus:enabled {
        color: $alohaPink;
        background-color: $white;
        box-shadow: 0 0 8px 0 $oceanBlue;
        .small {
          color: $secondaryText;
        }
      }
    }
  }
}


// utilities styles
.pink-header {
  color: $white;
  background: linear-gradient(135deg, $alohaPink 0, #85368a 100%);
  padding: 16px;
}

.nav-btn {
  cursor: pointer;
}

.step-desc {
  color: $lightText;
  font-weight: bold;
  background: transparent;
}

.icon-desc {
  color: $alohaPink;
  font-size: 2em;
  cursor: pointer;
}

.icon-desc-gray {
  color: $breezeGray;
  font-size: 2em;
  cursor: auto;
}

.mat-checkbox .mat-checkbox-frame {
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: $alohaPink !important;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.mat-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
}

.mat-expansion-indicator::after {
  vertical-align: text-top !important;
  padding: 4px !important;
  border-width: 0px 3px 3px 0px !important;
  color: $alohaPink !important;
}

/* Material Form Field & Mat Select overrides */
:host ::ng-deep {
  .mat-form-field {
    * {
      font-family: SlatePro,Helvetica,Arial,sans-serif;
    }

    .mat-form-field-flex {
      padding-top: 20px !important;
    }

    .mat-form-field-infix {
      border: 0 !important;
      padding: 5px 0 2px !important;
      &:focus {
        outline: none;
      }
    }

    .mat-input-element, .mat-select {
      background: $white !important;
      padding: 12px 16px !important;
      height: 48px !important;
      border: solid 1.5px rgba(0, 0, 0, 0.12) !important;

      &:hover {
        border: solid 2px $eveningGray !important;
      }

      &:focus {
        box-shadow: 0 0 8px #00a5ba;
      }
    }

    &.mat-form-field-invalid .mat-input-element,
    &.mat-warn .mat-input-element,
    .mat-select-invalid {
      border: solid 2px #F00 !important;

      &:hover {
        border: solid 2px #F00 !important;
      }
    }

    .mat-form-field-label, &.mat-form-field-invalid .mat-form-field-label {
      transition: none !important;
      font-size: 16px;
      padding: 10px 18px;
      color: $eveningGray;
    }

    &.ng-dirty.ng-valid .mat-form-field-label,
    &.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-should-float.mat-focused .mat-form-field-label {
      color: $black;
    }

    .mat-select-content {
      font-family: SlatePro,Helvetica,Arial,sans-serif !important;
      color: $secondaryText !important;
    }

    .mat-form-field-label-wrapper {
      top: -12px !important;
    }

    &.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label-wrapper {
        top: -18px !important;
      }

      .mat-form-field-label {
        transform: translateY(-1.34375em) scale(.875) !important;
        width: 133.33333%;
        padding: 0;
      }
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0) !important;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-suffix {
      position: absolute;
      right: 0;
      top: 25%;
    }

    .mat-form-field-subscript-wrapper {
      font-size: 14px;
      margin: 0;
      top: unset;
    }

    mat-error {
      padding-left: 10px;
      position: relative;
      &::before {
        content: "\25b8 ";
        font: 18px normal;
        left: -3px;
        position: absolute;
        top: -2px;
      }
    }
  }
}

::ng-deep  .mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0 !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $elitePlatinum;
}

::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  box-shadow: 0 0 8px rgba(0, 165, 190, 0.8);
  color: $elitePlatinum;
}

::ng-deep .mat-form-field-invalid.mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  box-shadow: none;
}

::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: $black;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: $sunsetOrange !important;
}

::ng-deep .mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.8) !important;
}

.mat-radio-inner-circle::after {
  content: "\e605";
  color: white;
  font-family: 'ha-icons' !important;
  font-size: 18px;
}

.language-buttons {
  margin-bottom: 1rem;
  text-align: center;
}

.link {
  color: $alohaPink;
  cursor: pointer;
}

.action {
  margin-top: 30px;
  text-align: center;
}

.full-width {
  width: 100%;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #555;
  padding: 150px 0;
  opacity: .5;

  .spinner-border {
    width: 10rem;
    height: 10rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -5rem;
    margin-left: -5rem;
  }
}

.Wordmark {
  width: 122px;
  height: 32px;
  object-fit: contain;
}

.mat-expansion-panel, .mat-card, .special-items-container {
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px;
  min-height: 80px;
  align-items: center;
  max-width: 100%;
  margin: 0 auto 16px !important;
}

app-checkin-header ~ .container {
  padding-bottom: 75px;
}


// print style
@media print {
  header, header * {
    visibility: hidden;
  }
  footer, footer * {
    visibility: hidden;
  }
  app-checkin-stepper, app-checkin-stepper * {
    visibility: hidden;
  }
  section.top {
    visibility: hidden;
  }
}

@mixin icon-size($intSize: 64) {
    width: #{$intSize}px;
    height: #{$intSize}px;
    font-size: #{$intSize/10}rem!important;
    line-height: 1;
}

.icon-64 {
  @include icon-size(64);
}

.icon-16 {
  @include icon-size(16);
}

.icon-24 {
  @include icon-size(24);
}

.icon-32 {
  @include icon-size(32)
}

/* membership icons */
$stars:
  'platinum' #CCC #999,
  'pltk' #CCC #999,
  'plat' #CCC #999,
  'gold' #FC6 #B19651,
  'premier' $alohaPink $royalPurple,
  'club' $alohaPink $royalPurple;

@each $name, $color1, $color2 in $stars {
  .icon-star-#{$name}-16:before {
    background-color: $color1;
    background-image: linear-gradient(190deg, $color1, $color2);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    content: "\e644"
  }
}

/* Stack icons: used to place the red 'not allowed' symbol on top of another icon.
    See: https://css-tricks.com/stackicons-icon-fonts */
.st-icon {position: relative}
.st-icon::after {
    position:absolute;
    top:0;
    left:0;
    font-family: "ha-icons" !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e936"; color:red
}


//icons
.icon-oversized-baggage88x64:before {
    @include icon-size(64);
}

.icon-overweight-baggage88x64:before {
    @include icon-size(64);
}

@media screen and (min-width: 1040px) {
  li.main a.secondary::before {
    background: linear-gradient(135deg,#ce0c88 0,#463c8f 100%);
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #0000;
  }

  li.main a.secondary:focus::before, li.main a.secondary:hover::before {
    background: #fff;
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #0000;
  }
}

@media screen and (min-width: 768px) {
  .mat-radio-inner-circle::after {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .mat-expansion-panel, .mat-card, .special-items-container, .mat-tab-group.mat-primary{
    border-radius: 0px !important;
    border-style: none none solid none;
    border-bottom: 1px solid #e1e3e4;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
    &:last-child {
      box-shadow: 0 8px 0 0 $backgroundGray, 0 9px 0 0 #e1e3e4 !important;
    }
  }
  .btn {
    border-radius: 0px !important;
    margin: 0 0 1rem 0 !important;
    min-width: 100% !important;
  }
  .mat-dialog-title, mat-dialog-content {
    text-align: left;
  }
  .mat-dialog-content {
    max-height: 60vh;
  }
  h4 {
    font-size: 32px;
    &.medium {
        font-size: 32px;
    }
  }

  .mat-checkbox .mat-checkbox-frame {
    border-radius: 0px;
  }

  ::ng-deep .mat-checkbox-checked .mat-checkbox-background {
    border-radius: 0px;
  }

  ::ng-deep .mat-checkbox-checked .mat-checkbox-background {
    background-color: $alohaPink !important;
  }

  .mat-checkbox .mat-checkbox-frame {
    border: solid 1px $elitePlatinum;
    background-color: $white
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    margin: 2px;
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 567px) {
  .mat-expansion-panel, .mat-card, .special-items-container {
    min-height: 76px;
  }
}
