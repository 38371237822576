@use 'main.scss' as *;
@import 'app/styles/checkin.scss';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

[class*="flag-before-"]::before, [class*="flag-after-"]::after {
  box-sizing: content-box;
}

body {
  @include font-body(1.6rem);
  background: $backgroundGray;
  color: $darkText;
  height: 100%;
  line-height: 1.65;
  margin: 0;
  padding: 0;
  text-align: center;
}

.ha-floral-background {
  background: $backgroundGray url('/media/images/backgrounds/page_bg_flower01a.png') left 10.5rem repeat-y;
}

app-root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > main {
    flex-grow: 1;
  }

  > * {
    flex-shrink: 0;
  }

  > header {
    background-color: $white;
    border-bottom: .1rem solid $borderGray;
    display: block;
    height: 6rem;
    position: relative;
    text-align: center;

    div.container {
      @include page-section;
      height: 100%;
      padding: 0;
      position: relative;
    }
  }
}

main {
  display: block;
  overflow: auto;
  position: relative;
  z-index: 1;

  section {
    @include page-section(left, 0, 2rem);
    background-color: $backgroundGray;
    padding: 3rem 2.75%;

    &.bleed {
      background: $backgroundGray;
      height: 45rem;
      max-width: none;
      width: auto;

      + section {
        margin-top: -7rem;
      }
    }

    + section {
      margin-top: 3rem;
    }
  }
}

a {
  color: $alohaPink;
  text-decoration: none;

  &:visited {
    color: $alohaPink;
  }

  &:hover, &:focus {
    color: $royalPurple;
    text-decoration: none;
  }
}

fieldset {
  border: none;
}

input {
  @include font-body(1.8rem);
}
#passportInformationForm {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
@media screen and (min-width: $headerWidthBreakMin) {
  app-root > header {
    height: 10.5rem;
  }
}

@media screen and (max-width: 768px) {
  body {
    background: $white;
  }
  .ha-floral-background {
    background: $white url('/media/images/backgrounds/page_bg_flower01a.png') left 10.5rem repeat-y;
  }
}

#mainContentBox {
  width: 85%;
}
